import { lazy, Suspense, useCallback, useMemo, useState } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../adapter/hooks';
// UI
import { KeraltyStepper } from '../../components/molecules';
import { Page, Container, Loader } from '../../components/templates';
// LOGIC
import CaptchaProvider from '../../contexts/CaptchaProvider';
import { useStepper } from '../../hooks/useStepper';
import { useRouteQuery } from '../../hooks/useRouteQuery';
// ADAPTER
import { registerActions } from '../../../adapter/register/registerSlice';
import registerSelectors from '../../../adapter/register/registerSelectors';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';
import moment from 'moment';

import FORMATS from 'app/ui-core/utils/formats';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import PrivateHttpClient from 'infrastructure/keraltyApi/privateHttpClient';
import { DaraUserECW } from 'domain/entities/accountInfo';

const { REGISTER: CURRENT, MAIN } = ROUTES;

interface ResponseValues {
	cause: string;
	tries: number;
	timer: number;
}

const RegisterFBPage = () => {
	const { t } = useTranslation();
	const { replace, push, goBack } = useHistory();
	const { step } = useParams();
	const { query } = useRouteQuery();
	const dispatch = useAppDispatch();
	const asyncError = useAppSelector(registerSelectors.selectError);
	const formValues = useAppSelector(registerSelectors.selectUserInfo);
	const isLoading = useAppSelector(registerSelectors.selectIsLoading);
	const accountNumber = useAppSelector(registerSelectors.selectAccountNumber);
	const partialRegister = useAppSelector(registerSelectors.selectPartialRegister);
	const endpoint = 'auth';
	const [timerLimit, setTimerLimit] = useState(30);
	const [valueStatus, setValue] = useState(5);
	const [errorVal, setErrorVal] = useState('');
	const [error, setError] = useState(false);
	const [response, setResponse] = useState({
		"cause": "VERIFIED",
		"tries": 0,
		"timer": 0
	});

	const steps = useMemo(() => [
		{
			label: t('register.contactSecurity'), route: CURRENT.confirm,
			component: lazy(() => import('./organisms/ConfirmLoginForm'))
		},
		{
			label: t('register.verification'), route: CURRENT.contact,
			component: lazy(() => import('./organisms/ContactInfoForm'))
		},
		{
			label: t('register.verification'), route: CURRENT.codeSent, hidden: true,
			component: lazy(() => import('./organisms/CodeSent'))
		},
		...(formValues?.contactMethod === 'phone' ?
			[{
				label: "", route: CURRENT.verify, hidden: true,
				component: lazy(() => import('./organisms/VerifySMSCode'))
			}]
			:
			[{
				label: "", route: CURRENT.oneMore, hidden: true,
				component: lazy(() => import('./organisms/OneMoreStep'))
			}]
		),
		{
			label: '', route: CURRENT.secondSent, hidden: true,
			component: lazy(() => import('./organisms/SecondSent'))
		}
	], [t, formValues]);

	//#region FLOW ACTIONS
	const handleFinish = useCallback(async () => {
		push(`/${MAIN.fb}/${MAIN.patientRegistration}`);
	}, [push]);

	const { currentStep, next, Step: FormStep } = useStepper(0, steps, handleFinish);

	const handleSuccessStep = async (value) => {
		dispatch(registerActions.setProp({
			userInfo: {
				...formValues,
				...(formValues?.birthdate ? { birthdate: moment(formValues.birthdate).format(FORMATS.date) } : {}),
				...value
			}
		}));
		let res;
		switch (step) {
			case CURRENT.confirm:
				// res = await dispatch(registerActions.isEmailAvailable(value.email)).unwrap();
				const valueDataEcw: DaraUserECW = {
					email: value.email,
					accountNumber: accountNumber !== undefined ? +accountNumber : 0,
					birthdate: moment(formValues.birthdate).format(FORMATS.date),
					phone: formValues.cellphone
				}
				res = await dispatch(registerActions.docreateUserEcw(valueDataEcw)).unwrap();
				break;
			case CURRENT.contact:
				res = await dispatch(registerActions.initialSave()).unwrap();
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ID_REGISTER, partialRegister.tempSessionId ?? '')
				dispatch(registerActions.updatePartialRegister({ tempSessionId: res.tempSessionId }));

				sessionStorage.setItem(SESSION_STORAGE_ITEMS.TEMP_SESSION, JSON.stringify({ ...partialRegister, tempSessionId: res.tempSessionId }));

				if (res?.cause === 'VERIFIED' || res?.cause === 'ATTEMPTS' || res?.cause === 'TRIES') {
					setResponse(res)
					push(`/${MAIN.register}/${CURRENT.fb}/${CURRENT.secondSent}`);
					return res
				}
				break;
			case CURRENT.codeSent: res = true; break;
			case CURRENT.secondSent:
				const code = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CODE)
				//const email = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.EMAIL)
				try {
					res = await dispatch(registerActions.loadMaxUserInfo({ id: code, email: "" })).unwrap();
					//push(`/${MAIN.patientRegistration}`);
					next()
				} catch (error: any) {
					if (error.message === '100' || error.message === '80') {
						setError(true)
					}
					return
				}

				break;
			case CURRENT.verify:
			case CURRENT.oneMore:
				setErrorVal('');
				const body = {
					"email": formValues?.email,
					"byEmail": (formValues?.contactMethod === 'phone' ? false : true),
					"state": formValues?.state ?? KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE),
					"code": value?.smsCode,
				};
				KeraltySWS?.swInstance?.setItem('code', value?.smsCode)
				const headers = {
					"Content-Type": "application/json",
					"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
				};
				try {
					const resValue: ResponseValues = await PrivateHttpClient.post(`${endpoint}/register/triesToBlock`, body, { headers: headers })

					setResponse(resValue)
					switch (resValue?.cause) {
						case "ATTEMPTS":
						case "VERIFIED":
							push(`/${MAIN.register}/${CURRENT.fb}/${CURRENT.secondSent}`);
							break
						default:
							if (resValue?.tries == 5) push(`/${MAIN.register}/${CURRENT.fb}/${CURRENT.secondSent}`);
							if (resValue?.tries <= 4) {
								if (resValue.tries == 0) {
									setValue(0)
									push(`/${MAIN.register}/${CURRENT.fb}/${CURRENT.secondSent}`);
								}
								timeOff(resValue.tries, resValue.timer)
							}
							break
					}
				} catch (error: any) {
					if (error.code === '160' || error.code === 160 || error.message === '160' || error.message === 160) {
						setValue(160)
						push(`/${MAIN.register}/${CURRENT.fb}/${CURRENT.secondSent}`);
						return
					}
				}

				break;
		}
		if (res) next();
	};

	const timeOff = (tries, timer) => {
		setTimerLimit(timer)
		if (tries > 0) {
			if (tries == 1) setErrorVal(t('register.trier') + tries + t('register.trier2'));
			else setErrorVal(t('errors.code150'));
		} else if (tries == 0) {
			setErrorVal(`${t('myAccount.limit1')} ${timer} ${t('myAccount.limit2')}`)
		}
	}

	const handleCleanAsyncError = useCallback(() => {
		dispatch(registerActions.setProp({ error: undefined }))
	}, [dispatch]);

	const handleMissingData = () => replace(`/${MAIN.register}`);
	//#endregion

	//#region UI LOGIC
	const title = useMemo(() => {
		const routesWithoutTitle: string[] = [CURRENT.codeSent, CURRENT.oneMore];
		if (routesWithoutTitle.indexOf(steps[currentStep].route) >= 0) return '';

		return currentStep === 0 ? t('register.confirmLoginInfo') : t('register.createAccount');
	}, [t, currentStep, steps]);

	const handleGoBack = useCallback(() => {
		handleCleanAsyncError();
		goBack();
	}, [handleCleanAsyncError, goBack]);

	const routeNames = useMemo(() => title ? [
		{ label: t('routes.login'), callback: () => replace(`/${MAIN.login}`) },
		{ label: title || t('routes.register') }
	] : [], [t, title, replace]);
	//#endregion

	return (
		<CaptchaProvider>
			{isLoading && <Loader />}
			<Page
				title={title}
				routes={routeNames}
				onPrevious={handleGoBack}
				previousLabel={t('routes.back')}
				sendCodeResponse={response} >
				<Container centered={'xy'} noPadding>
					<KeraltyStepper steps={steps} activeStep={currentStep == 3 ? 1 : currentStep} noPadding>
						<Suspense fallback={<Loader />}>
							<FormStep
								value={valueStatus}
								timer={timerLimit}
								errorVal={errorVal}
								error={error}
								response={response}
								setResponse={setResponse}
								formValues={formValues}
								onSuccess={handleSuccessStep}
								asyncError={asyncError}
								cleanAsyncError={handleCleanAsyncError}
								onMissingData={handleMissingData} />
						</Suspense>
					</KeraltyStepper>
				</Container>
			</Page>
		</CaptchaProvider>
	);
};

export default RegisterFBPage;
