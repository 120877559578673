import { useAppDispatch, useAppSelector } from "adapter/hooks";
import { confirmCode, requestCodeEmailMobile, resetInfoTwoFactor, stateName2FA, updateInfoTwoFactor, verifyEmailAndPassword } from "adapter/twoFactorAuthentication";
import { InfoTwoFactorAuthentication, TwoFactorAuthenticationState } from "adapter/twoFactorAuthentication/twoFactorAuthentication.state";
import { userActions } from "adapter/user/userSlice";
import { CodeEmailMobileRequest, ConfirmCodeRequest } from "domain/useCases/contracts/twoFactorAuthenticationService";
import { useState } from "react";
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import { useHistory } from 'react-router-dom';
import ROUTES from "app/ui-core/utils/routes";
import useCaptcha from "./useCaptcha";
import { KeraltySWS } from "./useKeraltySWS";
import { SESSION_STORAGE_ITEMS } from "app/ui-core/utils/constants";

const { MAIN } = ROUTES;

export const useTwoFactorAuthentication = () => {

	const STATUS_CODES = {
		ACCOUNT_BLOCKED: '401'
	};

    const dispatch = useAppDispatch();
    const history = useHistory();
    const { info: infoTwoFactor } = useAppSelector<TwoFactorAuthenticationState>(state => state.twoFactorAuthentication);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { handleValidCaptchaSubmit, captchaError } = useCaptcha('twoFactorAuthentication');

     const saveInfoInTwoFactorStore = (data: Partial<InfoTwoFactorAuthentication>): void => {
         dispatch(updateInfoTwoFactor(data));
     };

     const resetInfoInTwoFactorStore = (): void => {
        KeraltySWS?.swInstance?.deleteItem(`persist:${stateName2FA}`);
        dispatch(resetInfoTwoFactor());
     }

     /**
      * servicios
      */
     const getUserContactInfo = async (encryptedInfo: string) => {
        return await dispatch(verifyEmailAndPassword(encryptedInfo)).unwrap();
     }

     const getAuthenticationCode = async (payload: CodeEmailMobileRequest) => {
        return await dispatch(requestCodeEmailMobile(payload)).unwrap();
     }

     const sendAuthenticationCode = async (payload: ConfirmCodeRequest) => {
        return await dispatch(confirmCode(payload)).unwrap();
     }

     const login = async () => {
        handleValidCaptchaSubmit(async () => {

            const data = {
                email: infoTwoFactor.email,
                password: infoTwoFactor.password,
                state: infoTwoFactor.state,
            };

            try {
                const response = await dispatch(userActions.tryLogin(data)).unwrap();
                if(response && response !== '-1') {
                    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.STATE, infoTwoFactor.state);
                    dispatch(sanitasAdapterMethods.shutdown());

                    resetInfoInTwoFactorStore();

                    setIsLoading(false)

                    history.replace(`/${MAIN.home}`);
                }


            }catch(error){
                setIsLoading(false)
                console.log(error);
            }
        })


     }

    return {
        saveInfoInTwoFactorStore,
        resetInfoInTwoFactorStore,
        infoTwoFactor,
        getUserContactInfo,
        getAuthenticationCode,
        sendAuthenticationCode,
        login,
        setIsLoading,
        isLoading,
        captchaError,
		STATUS_CODES
    }
}
