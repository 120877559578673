import PublicHttpClient from '../../../../../infrastructure/keraltyApi/publicHttpClient';
import PublicTokenClient from '../../../../../infrastructure/keraltyApi/publicTokenClient';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
// UI
import { Divider, KeraltyButton, KeraltyError, KeraltyIcon, KeraltyText } from '../../../atoms';
import { KeraltyField, KeraltyModal } from '../../../molecules';
import { FormBox, CenteredWrapper, SpaceWrapper, Row, Column, Container, Loader } from '../../../templates';
import { ReactComponent as PlayStore } from 'app/assets/icons/GooglePlay_Button.svg';
import { ReactComponent as AppStore } from 'app/assets/icons/app-store.svg';
// ADAPTER
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import * as sanitasInteractions from 'app/interactions/sanitasChat';
import { userActions } from 'adapter/user/userSlice';
import { userSelectors } from 'adapter/user/userSelectors';
// LOGIC
import useCaptcha from '../../../../hooks/useCaptcha';
import { DefaultLogin, LoginSchema, LoginType } from '../../../../ui-core/schemas/login';
// STYLE
import './loginFormStyles.scss';

import { UserCredentials as UserCredentialsEntity } from 'domain/entities/userCredentials';
import IconFaceId from '../../../../../app/assets/images/faceid.png';
import IcontriangleExclamation from '../../../../../app/assets/images/triangle-exclamation.png';
import IconTouchId from '../../../../../app/assets/images/touchid.png';
import IconFaceIdDialog from '../../../../../app/assets/images/faceid-dialog.png';
import IconTouchDialog from '../../../../../app/assets/images/touchid-dialog.png';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CryptoJS from 'crypto-js';
import appConfig from 'config';
import useAuth from '../../../../hooks/useAuth';

import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import Select from 'react-select';

import { utcTime } from 'app/ui-core/utils/times';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import useUnderMaintenance from 'app/hooks/useUnderMaintenance';
import { useTwoFactorAuthentication } from 'app/hooks/useTwoFactorAuthentication';
import authMapper from 'infrastructure/keraltyApi/mappers/authMapper';
import { UserCredentialsSecurityDTO } from 'infrastructure/keraltyApi/models/userCredentialsSecurityDTO';
import ROUTES from 'app/ui-core/utils/routes';
import { stateName2FA } from 'adapter/twoFactorAuthentication';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { ACCOUNT_STATE, LOGIN_ERROR_CODES } from 'domain/valueObjects/login';
import useForgotPassword from '../../forgotPassword/useForgotPassword';

const KeraltyTextButtom = styled(KeraltyText)`
	background: #3ca70d !important;
	border-radius: 25px;
	margin-top: 25px;
	text-align: center;
	cursor: pointer;
	color: #fff;
	//margin-left: 22%;
	align-self: center !important;
	padding: 13px;
	font-size: 18px !important;
	/* width: 90% !important; */
	line-height: 1.9rem;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	width: 100% !important;
	}
`;

const KeraltyTextButtomModal = styled(KeraltyText)`
	background: #4E9D2D !important;
	border-radius: 25px;
	margin-top: 8px;
  margin-inline-start: 16px;
	text-align: center;
  height: 44px;
  align-content: center;
	cursor: pointer;
	color: #fff;
	align-self: center !important;
	font-size: 16px !important;
	text-align: center;
  font-family: 'Proxima Nova bold', 'Roboto', 'Lato', sans-serif;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	  width: 100% !important;
	}
`;

const KeraltyTextButtomModalLine = styled(KeraltyText)`
	background: #FFF !important;
  border: solid 2px #4E9D2D;
	border-radius: 25px;
	margin-top: 8px;
  margin-inline-start: 16px;
	text-align: center;
  height: 44px;
  align-content: center;
	cursor: pointer;
	color: #4E9D2D;
	align-self: center !important;
	font-size: 16px !important;
	text-align: center;
  font-family: 'Proxima Nova bold', 'Roboto', 'Lato', sans-serif;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	  width: 100% !important;
	}
`;

const ContentBtnModal = styled(Row)`
	flex-wrap: nowrap;
  margin-block: 20px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	  flex-wrap: wrap;
	}
`;

const KeraltyCustomButtom = styled(KeraltyButton)`
	background: #3ca70d !important;
	border-radius: 25px;
	margin-top: 25px;
	text-align: center;
	cursor: pointer;
	color: #fff;
	//margin-left: 22%;
	align-self: center !important;
	padding: 13px;
	font-size: 18px !important;
	width: 90% !important;
	line-height: 1.9rem;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	width: 100% !important;
	}
`;

const endpoint = 'core';
const endpointToken = 'core/opt-ins';

const { MAIN } = ROUTES;

const LoginForm = () => {
  const { t } = useTranslation();
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const { captchaError, handleValidCaptchaSubmit } = useCaptcha('login');
  const asyncError = useAppSelector(userSelectors.selectError);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isStorage, setIsStorage] = useState(false);

  useEffect(() => {
    if (!isStorage) {
      KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.PERSIST_REGISTER);
      setIsStorage(true);
    }
  }, [isStorage]);

  const [updateVersion, setUpdateVersion] = useState(false);
  const [updateVersionIos, setUpdateVersionIos] = useState(false);

  const [checkedBiometric, setChecked] = useState(
    KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CHECKED_BIOMETRIC) === 'true' ? true : false
  );
  const [isLoginDisabled, setIsLoginDisabled] = useState(checkedBiometric);
  const [open, setOpen] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalExpiredPassword, setModalExpiredPassword] = useState(false);
  const [modalExceededAttempts, setModalExceededAttempts] = useState(false);
  const [is_modalError_sso, setIsModalError_sso] = useState(false);
  const [modalBlackList, setModalBlackList] = useState(false);
  const [isActivedLoading, setActivedLoading] = useState(false);

  const [countAtt, setCountAtt] = useState(null);

  const { isUnderMaintenance } = useUnderMaintenance();
  const { getUserContactInfo, saveInfoInTwoFactorStore } = useTwoFactorAuthentication();
  const { openPublicChat } = useForgotPassword();

  const handlerOnClick = () => openPublicChat();

  const location = useLocation();
  const { search } = location;

  const parseQueryString = (queryString: string): { message: string; } | null => {
    const params = new URLSearchParams(queryString);
    const message = params.get('message');
    if (message) {
      return { message };
    }
    return null;
  }

  useEffect(() => {
    const result = parseQueryString(search)
    if (result?.message == ACCOUNT_STATE.EXPIRED_PASSWORD) {
      setModalExpiredPassword(true)
    }
    if (result?.message == ACCOUNT_STATE.BLOCK_ACCOUNT) {
      setModalExceededAttempts(true)
    }
    if (result?.message == ACCOUNT_STATE.ERROR_SSO) {
      setIsModalError_sso(true)
    }
  }, [search]);

  useEffect(() => {
    if (modal) setActivedLoading(false)
  }, [modal])

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = useForm<LoginType>({
    resolver: yupResolver(LoginSchema),
    defaultValues: DefaultLogin,
    context: { isLoginDisabled },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const styleButton = {
    color: '#3CA70D',
    marginRight: 60,
    marginLeft: 60,
    marginTop: 10,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    border: 'none',
    borderColor: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
  };

  const styleDiv = {
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 10,
    marginLeft: 0,
    marginRight: 0,
    flex: 1,
    align: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  };

  const styleText = {
    textAlign: 'center',
    marginRight: 7,
  };

  const styleIcon = {
    flex: 1,
    align: 'center',
    margin: 20,
    paddingBottom: 5,
    fontSize: 32,
    fontWeight: 'bold',
  };

  const styleHr = {
    color: '#fff',
    height: 0.001,
    width: 250,
  };

  const styleStores = {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
  };

  const openSanitasChat = async () => {
    await sanitasInteractions
      .setUpSanitasChat(dispatch, {
        actionColor: '#7cbc98',
        backgroundColor: '#7cbc98',
        name: '',
        email: '',
        state: '',
        currentTimeZone: `${utcTime()}`,
        authUid: '',
        sanitasAccountNumber: '',
        gender: '',
        birthday: '',
        customAttributes: { cType: 'support' },
      })
      .then(() => {
        sanitasInteractions.showSanitasChat(dispatch);
      });
  };

  useEffect(() => {
    // const subscription = watch((value, { name, type }) => {
    // 	// Remove previous submissions erros when fields change
    // });

    const getPublicToken = () => {
      const body = {
        password: `${appConfig.PUBLIC_VERSION_PSSWRD}`,
        user: `${appConfig.PUBLIC_VERSION_USER}`,
      };
      var resToken = '';
      return PublicTokenClient.post(`${endpointToken}/authenticate`, body).then((res) => {
        resToken = res['access_token'];
        loadVersion(resToken);
        loadVersionIos(resToken);
      });
    };
    const loadVersion = (publiToken) => {
      return PublicHttpClient.get(`${endpoint}/version/android`, {
        headers: { Authorization: `Bearer ` + publiToken },
      }).then((res) => {
        update(res);
      });
    };
    const loadVersionIos = (publiToken) => {
      return PublicHttpClient.get(`${endpoint}/version/ios`, {
        headers: { Authorization: `Bearer ` + publiToken },
      }).then((res) => {
        updateIos(res);
      });
    };

    const update = (currentVersion) => {
      var updateHelp = false;
      var burnedVersion: any = process.env.REACT_APP_MOBILE_VERSION;
      var decBurned =
        burnedVersion.slice(0, 1) + burnedVersion.slice(2, 3) + burnedVersion.slice(4, 5);
      var dec =
        currentVersion.slice(0, 1) + currentVersion.slice(2, 3) + currentVersion.slice(4, 5);
      var parsedVersion = parseInt(dec, 10);
      var parsedBurned = parseInt(decBurned, 10);

      if (parsedVersion <= parsedBurned) {
        updateHelp = false;
      } else {
        updateHelp = true;
      }
      setUpdateVersion(updateHelp);
    };

    const updateIos = (currentVersion) => {
      var updateHelp = false;
      var burnedVersion: any = process.env.REACT_APP_MOBILE_VERSION;
      var decBurned =
        burnedVersion.slice(0, 1) + burnedVersion.slice(2, 3) + burnedVersion.slice(4, 5);
      var dec =
        currentVersion.slice(0, 1) + currentVersion.slice(2, 3) + currentVersion.slice(4, 5);
      var parsedVersion = parseInt(dec, 10);
      var parsedBurned = parseInt(decBurned, 10);

      if (parsedVersion <= parsedBurned) {
        updateHelp = false;
      } else {
        updateHelp = true;
      }
      setUpdateVersionIos(updateHelp);
    };

    if (asyncError && isDirty) {
      dispatch(userActions.cleanError());
    }

    // return () => subscription.unsubscribe();
  }, [asyncError, dispatch, isDirty]);

  useEffect(() => {
    //console.log('history', history,'windowHistory', window.history,'leght', window.history.length,'location',window.location, document.referrer)
    //console.log('past', window)

    KeraltySWS?.swInstance?.deleteItem(`persist:${stateName2FA}`);

    if (
      KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CHECKED_BIOMETRIC) === 'true' &&
      KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.HAVE_LOGED) !== 'true'
    ) {
      if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED) !== null) {
        window.Fingerprint.show(
          {
            clientId: 'Fingerprint-kdc',
            clientSecret: 'password',
            disableBackup: false,
            confirmationRequired: true,
          },
          (result) => {
            let bytesBackCredential = CryptoJS.AES.decrypt(
              KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED) || '{}',
              'MyS4n1t4s'
            );
            let userBackCredential: UserCredentialsEntity = JSON.parse(
              bytesBackCredential.toString(CryptoJS.enc.Utf8)
            );
            dispatch(userActions.tryLogin(userBackCredential));
            dispatch(sanitasAdapterMethods.shutdown());
          },
          (error) => {
            //	alert("ERRROR : " + JSON.stringify(error));
            if (error.code !== -108 && error.code !== -109) {
              alert('(' + error.code + ') : ' + t('errorBiometricMessage'));
              //handleVeryfyPlatform() === 'ios' ? alert("Authentication Face ID  failed : " + JSON.stringify(error)) : alert("Authentication Touch ID  failed :" + JSON.stringify(error));
              //	handleVeryfyPlatform() === 'ios' ? alert("Authentication Face ID  failed : " + error.message) : alert("Authentication Touch ID  failed :" + error.message);
            }
          }
        );
      } else {
        setIsLoginDisabled(false);
      }
    } else {
      //console.log('noCordoba')
    }
  }, []);

  const [userCredents, setCredent] = useState<Omit<UserCredentialsEntity, 'phone'>>();

  useEffect(() => {
    KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ERROR_SECURITY);
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.LOGGED, '');
    sessionStorage.removeItem(SESSION_STORAGE_ITEMS.TEMP_SESSION);
  }, [])

  useEffect(() => {
    if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ERROR_SECURITY) === '-1') {
      setActivedLoading(false);
      setModalExpiredPassword(true);
    }
  }, [KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ERROR_SECURITY)])

  const onValidSubmit = useCallback((userCredentials) => {

    if (isUnderMaintenance()) return;

    setCredent(userCredentials);
    setActivedLoading(true)
    KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ERROR_SECURITY);
    setCountAtt(null)
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.NEW_VISIT, 'true');
    if (
      KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CHECKED_BIOMETRIC) === 'true' &&
      userCredentials.password === ''
    ) {
      if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED) !== null) {
        window.Fingerprint.show(
          {
            clientId: 'Fingerprint-kdc',
            clientSecret: 'password',
            disableBackup: false,
            confirmationRequired: true,
          },
          (result) => {
            console.log('LOCAL STORAGE: ---> ' + KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED));
            let bytesBackCredential = CryptoJS.AES.decrypt(
              KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED) || '{}',
              'MyS4n1t4s'
            );
            let userBackCredential: UserCredentialsEntity = JSON.parse(
              bytesBackCredential.toString(CryptoJS.enc.Utf8)
            );
            dispatch(userActions.tryLogin(userBackCredential));
            dispatch(sanitasAdapterMethods.shutdown());
          },
          (error) => {
            //	alert("ERRROR : " + JSON.stringify(error));
            if (error.code !== -108 && error.code !== -109) {
              alert('(' + error.code + ') : ' + t('errorBiometricMessage'));
              //handleVeryfyPlatform() === 'ios' ? alert("Authentication Face ID  failed : " + JSON.stringify(error)) : alert("Authentication Touch ID  failed :" + JSON.stringify(error));
              //	handleVeryfyPlatform() === 'ios' ? alert("Authentication Face ID  failed : " + error.message) : alert("Authentication Touch ID  failed :" + error.message);
            }
          }
        );
      } else {
        setIsLoginDisabled(false);
      }
    } else {
      handleValidCaptchaSubmit(async () => {
        //if the user is set in more than two states show popup
        let response = await dispatch(
          userActions.tryAllStates(userCredentials as Omit<UserCredentialsEntity, 'phone'>)
        ).unwrap().catch(() => {
          setCountAtt(t('errors.loginFailure'))
          setActivedLoading(false)
        });

        // console.log({response, userCredentials})
        // return;

        /**
         * si response.length === 1, guardar el estado
         */

        if (!response) return;

        let responseEntero = parseInt(response[0]);

        if (response.length > 1) {
          setModal(true);
          setCredent(userCredentials);
          setValue('state', response[0]);
        } else if (response.length == 1) {

          switch (responseEntero) {
            case 4:
              return ErrorsExceededAttempts(4);
            case 3:
              return ErrorsExceededAttempts(3);
            case 2:
              return ErrorsExceededAttempts(2);
            case 1:
              return ErrorsExceededAttempts(1);
            case 0:
              return setModalExceededAttempts(true);
            case -1:
              return setModalExpiredPassword(true);
            case -2:
              return setModalBlackList(true);
            default:

              // aqui se guarda el estado y se redirecciona

              login({ userCredentials, state: response[0] });

              // (
              //   localStorage.setItem('state', response[0]),
              //   (userCredentials.state = response[0]),
              //   dispatch(userActions.tryLogin(userCredentials)),
              //   dispatch(sanitasAdapterMethods.shutdown())
              // );
              break;
          }
        } else {
          KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.STATE, response[0]);
          userCredentials.state = response[0];
          dispatch(userActions.tryLogin(userCredentials));
          dispatch(sanitasAdapterMethods.shutdown());
        }
      });
    }

    reset({ ...userCredentials });
  },
    [dispatch, reset, t, handleValidCaptchaSubmit]
  );

  const login = async ({ userCredentials, state }) => {

    try {
      userActions.tryLogin(userCredentials)

      const data = {
        email: userCredentials.email,
        password: userCredentials.password,
        state
      }

      const { info }: UserCredentialsSecurityDTO = authMapper.mapToUserSecuirtyCredentials(data);
      const { phone } = await getUserContactInfo(info);

      KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.STATE, state);

      saveInfoInTwoFactorStore({ ...data, encryptedInfo: info, phone })

      history.replace(`/${MAIN.authentication}`);

    } catch (error: any) {
      setActivedLoading(false);
      if (error.message === LOGIN_ERROR_CODES.EXPIRED_PASSWORD) {
        setModalExpiredPassword(true);
      }
    }

  }

  const ErrorsExceededAttempts = (attempts) => {
    if (attempts >= 2 && attempts <= 4) setCountAtt(t('InfoDoesntMatch'));
    if (attempts === 1) setCountAtt(t('InfoDoesntMatchLastTry'));

    setActivedLoading(false)
  };

  const stateOptions: any[] = [
    { value: 'FL', label: 'Florida' },
    { value: 'TN', label: 'Tennessee' },
  ];

  //const stateOptions: any[] = [{ value: 'FL', label: 'FL' }];

  const ModalExpiredPassword = () => {
    return (
      <KeraltyModal size="medialong" textalign="center">
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center' }}>
          <KeraltyIcon iconClass="fas fa-exclamation" color="accent" size="medium" rounded />
        </Row>
        <Row noMargin>
          <KeraltyText fontSize={'20px'} type={'title'} color={'primary'}>
            {t('accountBlocked')}
          </KeraltyText>
        </Row>
        <Row noMargin>
          <KeraltyText type={'info'} color={'primary'}>
            {t('modalPasswordExpired')}
          </KeraltyText>
        </Row>
        <div className="unblock-account" style={{ display: 'grid', placeItems: 'center' }}>
          <KeraltyTextButtom link to="unblock-account" type="label" undecorated bold>
            {t('unblockAccount2')}
          </KeraltyTextButtom>
        </div>
      </KeraltyModal>
    );
  };

  const ModalExceededAttempts = () => {
    return (
      <KeraltyModal size="medialong" textalign="center">
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center' }}>
          <KeraltyIcon iconClass="fas fa-exclamation" color="accent" size="medium" rounded />
        </Row>
        <Row noMargin>
          <KeraltyText fontSize={'20px'} type={'title'} color={'primary'}>
            {t('accountBlocked')}
          </KeraltyText>
        </Row>
        <Row noMargin>
          <KeraltyText type={'info'} color={'primary'}>
            {t('modalExceededAttempts')}
          </KeraltyText>
        </Row>
        <div className="unblock-account" style={{ display: 'grid', placeItems: 'center' }}>
          <KeraltyTextButtom link to="unblock-account" type="label" undecorated bold>
            {t('unblockAccount2')}
          </KeraltyTextButtom>
        </div>
      </KeraltyModal>
    );
  };

  const ModalError_sso = () => {
    return (
      <KeraltyModal size="medialong" textalign="center" style={{ width: '500px' }}>
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center' }}>
          <img src={IcontriangleExclamation} alt="FaceId Icon" height="42px" width="48px" />
        </Row>
        <Row noMargin>
          <KeraltyText fontSize={'20px'} type={'title'} color={'#E7A304'} fontF >
            {t('modalErrorSSOTitle')}
          </KeraltyText>
        </Row>
        <Row noMargin>
          <KeraltyText type={'info'} color={'#212121'} >
            {t('modalErrorSSO')}
          </KeraltyText>
        </Row>
        <ContentBtnModal noMargin style={{ justifyContent: 'center' }}>
          <KeraltyTextButtomModalLine type="info" bold undecorated onClick={() => {
            setIsModalError_sso(false);
            history.replace('/login');
          }}>
            {t('modalErrorSSO_msj_retry')}
          </KeraltyTextButtomModalLine>
          <KeraltyTextButtomModal type="info" bold undecorated onClick={() => {
            setIsModalError_sso(false);
            history.replace('/login');
            handlerOnClick();
          }}>
            {t('modalErrorSSO_msj_support')}
          </KeraltyTextButtomModal>
        </ContentBtnModal>
      </KeraltyModal>
    );
  };

  const ModalBlackList = () => {
    setActivedLoading(false);
    return (
      <KeraltyModal size="medialong" textalign="center">
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center' }}>
          <KeraltyIcon iconClass="fas fa-exclamation" color="accent" size="medium" rounded />
        </Row>
        <Row noMargin>
          <KeraltyText fontSize={'20px'} type={'title'} color={'primary'}>
            {t('accountBlocked')}
          </KeraltyText>
        </Row>
        <Row noMargin>
          <KeraltyText type={'info'} color={'primary'}>
            {t('modalBlackList')}
          </KeraltyText>
          <KeraltyText
            onClick={() => {
              setModalBlackList(false);
              openSanitasChat();
              setModal(false);
              setModalExceededAttempts(false);
              setIsModalError_sso(false);
              setModalExpiredPassword(false);
            }}
            style={{ cursor: 'pointer' }}
            underline
            bold
            type={'info'}
            color={'primary'}
          >
            {t('patientSupport')}
          </KeraltyText>
        </Row>
        <Row noMargin>
          <Row>
            <KeraltyButton
              full
              onClick={() => {
                setModalBlackList(false);
                setModalExceededAttempts(false);
                setIsModalError_sso(false);
                setModalExpiredPassword(false);
                setModal(false);
              }}
            >
              {t('myAccount.close')}
            </KeraltyButton>
          </Row>
        </Row>
      </KeraltyModal>
    );
  };

  const ModalLoginState = () => {
    return (
      <KeraltyModal size="long" textalign="center">
        <Row alingItemsCenter style={{ justifyContent: 'center' }}>
          <KeraltyIcon iconClass="fas fa-route-interstate" color="accent" size="medium" rounded />
        </Row>
        <Row>
          <KeraltyText type={'info'} color={'primary'}>
            {t('modalState')}
          </KeraltyText>
        </Row>
        <Row>
          <KeraltyField
            control={Select}
            title={t('imgNicons.mapMarkerIcon')}
            iconClass={'fal fa-route-interstate'}
            onChange={({ value }) => setValue('state', value, { shouldValidate: true })}
            placeholder={t('register.state')}
            type={'select'}
            options={stateOptions}
            value={stateOptions.find(({ value }) => value === watch('state'))}
            error={errors.state}
          />
        </Row>
        <Divider mx={0.5} />
        <Row>
          <Row noMargin>
            <Container end={'x'} noPadding>
              <div style={{ display: 'flex' }}>
                <div style={{ padding: '10px', cursor: 'pointer' }} onClick={() => setModal(false)}>
                  <KeraltyText color="#B4B4B4" type="info" align="center" pb bold>
                    {t('myAccount.textCancel')}
                  </KeraltyText>
                </div>
                <div style={{ padding: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    handleOnClose();
                    setModal(false)
                  }}
                >
                  <KeraltyText
                    color="#3CA70D"
                    type="info"
                    align="center"
                    pb
                    bold
                  >
                    {t('ok')}
                  </KeraltyText>
                </div>
              </div>
            </Container>
          </Row>
        </Row>
      </KeraltyModal>
    );
  };

  const handleOnClose = () => {
    setModal(false);

    //Set the state in UserCredentialsEntity and send post
    if (typeof userCredents != 'undefined') {
      userCredents.state = watch('state');
      KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.STATE, userCredents.state ?? '');
    }

    login({ userCredentials: userCredents, state: userCredents?.state ?? '' });

    // dispatch(userActions.tryLogin(userCredents as Omit<UserCredentialsEntity, 'phone'>));
    // dispatch(sanitasAdapterMethods.shutdown());
  };

  const pwdIcon = (visible: boolean) => (
    <KeraltyIcon
      key={visible ? 'eye' : 'eye-slash'}
      title={visible ? t('imgNicons.eyeIcon') : t('imgNicons.slashedEyeIcon')}
      iconClass={`fas fa-eye${(!visible && '-slash') || ''}`}
    />
  );

  const handleVeryfyPlatform = () => {
    let platform;
    // if IOS
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      platform = 'ios';
      // If Android
    } else if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      platform = 'android';
      // If other OS
    } else {
      platform = 'other';
    }

    return platform;
  };

  const handleChangeBiometricCheck = () => {
    setChecked(!checkedBiometric);
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.CHECKED_BIOMETRIC, (!checkedBiometric).toString());
    setIsLoginDisabled(!checkedBiometric);
    if (!checkedBiometric) {
      handleOpenDisclaimer();
      if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED) === null) {
        setIsLoginDisabled(false);
      }
    }
  };

  const CheckboxBiometric = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'inline-block' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <label style={{ paddingRight: '10px', fontSize: '1.33rem' }}>
              <input
                type="checkbox"
                checked={checkedBiometric}
                onChange={handleChangeBiometricCheck}
              />
              {handleVeryfyPlatform() === 'ios'
                ? t('checkBiometricTextIOS')
                : t('checkBiometricTextAndroid')}
            </label>
            {handleVeryfyPlatform() === 'ios' ? (
              <img src={IconFaceId} alt="FaceId Icon" height="20px" width="20px" />
            ) : (
              <img src={IconTouchId} alt="TouchId Icon" height="20px" width="20px" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleOpenDisclaimer = () => {
    if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED) === null) {
      setOpen(true);
    }
  };

  const handleAccepDisclaimer = () => {
    setOpen(false);
    setChecked(true);
  };

  const handleCloseDisclaimer = () => {
    setOpen(false);
    setChecked(false);
  };

  const CustomConfirmDialog = () => {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDisclaimer}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: '15px', margin: '22px' },
          }}
        >
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            {handleVeryfyPlatform() === 'ios' ? (
              <img src={IconFaceIdDialog} alt="FaceId Icon" height="40px" width="40px" />
            ) : (
              <img src={IconTouchDialog} alt="TouchId Icon" height="40px" width="40px" />
            )}
          </div>

          <DialogTitle
            style={{
              paddingTop: '5px',
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#3CA70D',
              textAlign: 'center',
            }}
            id="alert-dialog-title"
          >
            {handleVeryfyPlatform() === 'ios'
              ? t('disclaimerBioTitleIOS')
              : t('disclaimerBioTitleAndroid')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ fontSize: '16px', textAlign: 'center' }}
              id="alert-dialog-description"
            >
              {t('disclaimerBioContent')}
            </DialogContentText>
          </DialogContent>
          <div style={{ borderTop: '1px solid #D5D5D5', marginLeft: 20, marginRight: 20 }}></div>
          <DialogActions>
            <Button
              style={{ fontSize: '14px', width: '49%', color: 'rgba(0, 0, 0, 0.6)' }}
              onClick={handleCloseDisclaimer}
            >
              {t('diclaimerBioDisagree')}
            </Button>
            <Button
              style={{ fontSize: '14px', fontWeight: 'bold', color: '#3CA70D', width: '49%' }}
              onClick={handleAccepDisclaimer}
            >
              {t('disclaimerBioAgree')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <>
      {isActivedLoading && <Loader />}
      <FormBox onSubmit={handleSubmit(onValidSubmit)}>
        {(updateVersionIos && handleVeryfyPlatform() === 'ios') || updateVersion ? (
          <KeraltyModal size="long">
            <Row noMargin>
              <KeraltyIcon
                iconClass="far fa-exclamation-triangle"
                color="accent"
                size="big"
                style={styleIcon}
                mx
              />
              <SpaceWrapper mb={1}>
                <Container centered={'x'} noPadding noMargin>
                  {handleVeryfyPlatform() === 'ios' ? (
                    <KeraltyText type={'label'} align={'center'} style={styleText}>
                      {t('updateNoticeTextIos')}
                    </KeraltyText>
                  ) : (
                    <KeraltyText type={'label'} align={'center'} style={styleText}>
                      {t('updateNoticeText')}
                    </KeraltyText>
                  )}
                </Container>
              </SpaceWrapper>
              <hr style={styleHr} />
            </Row>
            <Row noMargin>
              <div style={styleDiv}>
                <button
                  style={styleButton}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (handleVeryfyPlatform() === 'ios') {
                      window.location.href = 'https://apps.apple.com/us/app/mysanitas/id1600791076';
                    } else {
                      window.location.href =
                        'https://play.google.com/store/apps/details?id=com.keralty.mysanitas';
                    }
                  }}
                >
                  {' '}
                  {t('updateButtonText')}
                </button>
              </div>
            </Row>
          </KeraltyModal>
        ) : null}

        <Row>
          <Column lg={0}>
            <SpaceWrapper mt={1}></SpaceWrapper>
          </Column>
        </Row>

        <KeraltyField
          iconClass={'fas fa-envelope'}
          title={t('imgNicons.envelopeIcon')}
          placeholder={t('formLabel.email')}
          label={t('formLabel.email')}
          field={register('email')}
          error={errors.email}
          onClick={() => isUnderMaintenance()}
        />
        <KeraltyField
          iconClass={'fas fa-lock-alt'}
          title={t('imgNicons.lockIcon')}
          actionIcon={pwdIcon(isPwdVisible)}
          onActionIconClick={() => setIsPwdVisible(!isPwdVisible)}
          placeholder={t('formLabel.password')}
          label={t('formLabel.password')}
          field={register('password')}
          type={isPwdVisible ? 'text' : 'password'}
          error={errors.password}
          onClick={() => isUnderMaintenance()}
        />

        <KeraltyError error={countAtt ? countAtt : null} />

        {/* {captchaError && !asyncError && <KeraltyError error={captchaError} />} */}

        <div className="send-button">
          <KeraltyButton type={'submit'} full>
            {t('loginButtonText')}
          </KeraltyButton>
        </div>
        <SpaceWrapper mt={3}></SpaceWrapper>
        <CenteredWrapper direction="horizontal">
          <div className="forgot-pass">
            <KeraltyText
              onClick={() => {
                if (isUnderMaintenance()) return;
                history.push("/forgot-password")
              }}
              type="label" undecorated bold
            >
              {t('forgotPass')}
            </KeraltyText>
          </div>
        </CenteredWrapper>
        <SpaceWrapper mt={1.25}></SpaceWrapper>
        <CenteredWrapper direction="horizontal">
          <div className="create-account">
            <KeraltyText
              data-tip={t('register.passwordInfo')}
              // link
              // to="register"
              type="label"
              undecorated
              onClick={() => {
                KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.INSURANCE_FORM_DATA)
                if (isUnderMaintenance()) return;
                history.push("/register")
              }}
            >
              {t('createAccount')}

            </KeraltyText>
          </div>
        </CenteredWrapper>
        {window.innerWidth < 991 ? (
          <>
            <CenteredWrapper direction="horizontal">
              <div className="create-account" style={{ paddingTop: 25 }}>
                <KeraltyText type="label" style={{ fontWeight: 400 }} undecorated>
                  {t('download')}
                </KeraltyText>
              </div>
            </CenteredWrapper>
            <div style={styleStores}>
              <div
                style={{ paddingInline: 5 }}
                onClick={() =>
                (window.location.href =
                  'https://play.google.com/store/apps/details?id=com.keralty.mysanitas')
                }
              >
                <PlayStore />
              </div>
              <div
                style={{ paddingInline: 5 }}
                onClick={() =>
                  (window.location.href = 'https://apps.apple.com/us/app/mysanitas/id1600791076')
                }
              >
                <AppStore />
              </div>
            </div>
          </>
        ) : null}
        {modal ? (
          <ModalLoginState />
        ) : modalExceededAttempts ? (
          <ModalExceededAttempts />
        ) : is_modalError_sso ? (
          <ModalError_sso />
        ) : modalExpiredPassword ? (
          <ModalExpiredPassword />
        ) : modalBlackList ? (
          <ModalBlackList />
        ) : null}
      </FormBox>
    </>
  );
};

export default LoginForm;
