import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'adapter/hooks';
import { trySSOLogin } from 'adapter/user/userSlice';
// Actions
import useAuth from '../hooks/useAuth';
import { Loader } from '../components/templates';
import { userActions } from 'adapter/user/userSlice';
import { updateInfoTwoFactor } from 'adapter/twoFactorAuthentication';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';

const HandlerSSO = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { search } = location;
  const [isLoading, setIsLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);

  const parseQueryString = (queryString: string): { email: string; phone: string } | null => {
    const params = new URLSearchParams(queryString);

    const email = params.get('email');
    const phone = params.get('mobile');

    if (email && phone) {
      return { email, phone };
    }

    return null;
  }

  const ssoAuthentication = async () => {
    if (search) {
      const result = parseQueryString(search)

      if (!!result?.email && !!result?.phone) {
        KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.STATE, "FL")
        dispatch(userActions.setLocation({ value: 'Florida', label: 'Florida' }));
        dispatch(updateInfoTwoFactor({ ...result, state: "FL" }));
        setUserExists(true)
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const token = search.split('=')[1];
    const action = search.split('=')[2];

    const dispatchSSOLogin = async () => {
      if (search) {
        await dispatch(trySSOLogin(token));
      }
    };

    if (action == 'login') dispatchSSOLogin();
    else ssoAuthentication();

    setIsLoading(false);
  }, [dispatch, search]);


  const showLoader = isLoading || user.status === 'pending';
  if (showLoader) return <Loader />;

  return user?.authUid ? (
    <Redirect to={{ pathname: '/home' }} />
  ) : userExists ? (
    <Redirect to={{ pathname: '/authentication/choose-option' }} />
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );
};

export default HandlerSSO;
