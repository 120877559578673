import { lazy, Suspense, useMemo, Fragment, useCallback, useEffect, useState } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// UI
import { KeraltyStepper, KeraltyDotChatTrigger, KeraltyModal } from '../../components/molecules';
import { Page, Container, Loader, Column, SpaceWrapper, Row } from '../../components/templates';
import PreLoginHeader from '../../components/molecules/preLoginHeader/PreLoginHeader';
import { capitalize } from '../../ui-core/utils/helpers/format';
// LOGIC
import { useStepper } from '../../hooks/useStepper';
import useIsMobile from '../../hooks/useIsMobile';
// ADAPTER
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { RegisterState } from '../../../adapter/register/registerState';
import { registerActions } from '../../../adapter/register/registerSlice';
import { userActions } from 'adapter/user/userSlice';
import registerSelectors from '../../../adapter/register/registerSelectors';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';
import { KeraltyButton, KeraltyText, KeraltyError, KeraltyIcon } from 'app/components/atoms';

import { ReactComponent as SupportChatIcon2 } from '../../assets/icons/newChat.svg';
import { ReactComponent as SupportChatIcon } from '../../assets/icons/chat 2.svg';
import * as sanitasAdapterMethods from '../../../adapter/sanitasChat/sanitasChat';
import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import { RootState } from 'adapter/store';
import useAuth from 'app/hooks/useAuth';
import { useRouteQuery } from 'app/hooks/useRouteQuery';

import { utcTime } from 'app/ui-core/utils/times';
import { ModalMaintenance } from 'app/components/molecules/ModalMaintenance';
import useMaintenanceAndNotifications from 'app/hooks/useMaintenanceAndNotifications';

const { PATIENT_REGISTRATION: CURRENT, MAIN } = ROUTES;

const TextSubtitle = styled(Column)`
	color:#5B5C5B !important;
	margin-top: -20px;
	margin-bottom: -15px;
	text-align: center;
	font-size: 16px;
	line-height: 1.58rem;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #5b5c5b ;
		margin-bottom: 20px !important;
		padding-left:2px;
	}
`;

const DoneButton = styled(Column)`
	font-family:'Proxima Nova', 'Roboto', 'Lato', sans-serif;
	background: #3ca70d !important;
	border-radius: 25px;
	text-align: center;
	cursor: pointer;
	color: #fff;
	align-self: center !important;
	padding: 13px;
	font-size: 18px !important;
	width: 200px;
	line-height: 1.9rem;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 30% !important;
		}
`;

const PatientRegistrationPage = () => {
	const { t } = useTranslation();
	const { replace, goBack, location } = useHistory();
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const isLoading = useAppSelector(registerSelectors.selectIsLoading);
	const asyncError = useAppSelector(registerSelectors.selectError);
	const formValues = useAppSelector(registerSelectors.selectPatientInfo);
	const userInfo: any = useAppSelector(registerSelectors.selectUserInfo);
	const isFBMax = useAppSelector(registerSelectors.selectIsFBMax);
	const [serverError, setserverError] = useState(false)
	const history = useHistory();
	const [showModalExpired, setShowModalExpired] = useState<boolean | null>(false);
	//const user = useAppSelector(state => state.user)

	const { scheduleText, modal} =  useMaintenanceAndNotifications();

	const { user } = useAuth();

	const { query } = useRouteQuery();
	const id = query.get('id') || 'nothing';

	const steps = useMemo(() => [
		{
			label: t('patientRegistration.stepTitleA'), route: CURRENT.responsible,
			component: lazy(() => import('./organisms/ResponsiblePartyForm'))
		},
		// NO_MAX USER NEEDS TO FILL INSURANCE INFORMATION
		...(isFBMax ? [] : [{
			label: t('patientRegistration.stepTitleB'), route: CURRENT.insurance,
			component: lazy(() => import('./organisms/InsuranceInfoForm'))
		}]),
		{
			label: t('patientRegistration.stepTitleC'), route: CURRENT.consent,
			component: lazy(() => import('./organisms/ConsentsForm'))
		}
	], [isFBMax]);

	//#region FLOW ACTIONS
	const handleFinish = useCallback(async () => {
		const token = await dispatch(registerActions.createAccount()).unwrap();
		//console.debug(token);
		if (isFBMax) dispatch(userActions.setLocation({ value: 'Florida', label: 'Florida' }));
		if (token) replace(`/${MAIN.sso}?id=${token}=login`);
	}, [dispatch, replace, isFBMax]);

	const {
		currentStep, next, prev, Step: FormStep
	} = useStepper(0, steps, handleFinish);


	const handleSuccessStep = useCallback((key: keyof RegisterState['patientInfo'], value: any) => {

		dispatch(registerActions.setPatientInfoProp({ [key]: value }));
		next();

	}, [dispatch, next]);

	const handleCleanAsyncError = () => dispatch(registerActions.setProp({ error: undefined }));

	const handleMissingData = () => replace(`/${MAIN.register}`);
	//#endregion

	//#region UI LOGIC
	const routeNames = useMemo(() => [
		{ label: t('routes.login'), callback: () => replace(`/${MAIN.login}`) },
		{ label: t('routes.patientRegistration') },
	], [t, replace]);
	//#endregion

	function toLogin() {replace('/login')}


	const backPage = () => {
		if(location.pathname !== '/patient-registration/responsible-party'){
			goBack()
			return
		}

		toLogin()
	}



	const triggerPatientSupport = useCallback(async () => {
		await setTimeout(async () => {
			await dispatch(sanitasAdapterMethods.setUp({
				actionColor: "#7cbc98",
				backgroundColor: "#7cbc98",
				name: '',
				email: '',
				state: '',
				currentTimeZone: `${utcTime()}`,
				authUid: '',
				sanitasAccountNumber: '',
				gender: '',
				birthday: '',
				customAttributes: { cType: 'patientSupport' },
			}));
			await dispatch(sanitasAdapterMethods.show());
		}, 500);
	}, [dispatch]);

	const handlerOnClick = async () => {
		// triggerPatientSupport();
		dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: 'support', state: true }));
	};


	const ModalExpiredTime = () => {
		return (
			<KeraltyModal size={isMobile ? 'long' : 'extraLong'} textalign="center">
				<div style={{marginTop: '10%', marginBottom: '20%'}}>
					<Row alingItemsCenter style={{ justifyContent: 'center' }}>
						<KeraltyIcon iconClass="fa fa-exclamation" color="accent" size="medium" rounded />
					</Row>
					<Row>
						<KeraltyText fontSize='20px' type={'info'} color={'primary'} style={{lineHeight: '20px'}}>
							{ t('errors.code101') }
						</KeraltyText>
					</Row>
					<div style={{display: 'grid', placeItems: 'center', marginTop: 20}}>
						<DoneButton onClick={() => history.replace('/login')}>
							{t('myAccount.close')}
						</DoneButton>
					</div>
				</div>
			</KeraltyModal>
		);
	};

	return (
		<>
			{ showModalExpired ? <ModalExpiredTime /> : <></>}
			<Fragment>
				{isLoading && <Loader />}
				<PreLoginHeader />
				<Page
					title={isMobile ? '' : t('routes.patientRegistration')}
					subtitle={capitalize(userInfo?.displayName || '')}
					hideMobileTitle
					routes={  currentStep != 0 ?  routeNames : undefined}
					previousLabel={ currentStep != 0 ? t('routes.back') : undefined}
					onPrevious={ currentStep != 0 ? () => backPage() : undefined}
					className={'right-side-page-arc page-title'}>
					{modal && <ModalMaintenance scheduleText={scheduleText} />}
					<Row>
						<Container centered={'xy'} noPadding>
							<Container centered={'xy'} noPadding>
								<KeraltyStepper steps={steps} activeStep={currentStep} noPadding>
									<Column lg={0} md={0} sm={12}>
										<SpaceWrapper mb={2}>
											<KeraltyText type={'title'} align="left" noMargin>{t('routes.patientRegistration')}</KeraltyText>
											<KeraltyText type={'label'}>{capitalize(userInfo?.displayName || '')}</KeraltyText>
										</SpaceWrapper>
									</Column>
									<Suspense fallback={<Loader />}>
										<FormStep
											formValues={formValues}
											onSuccess={handleSuccessStep}
											onBack={() => prev()}
											asyncError={asyncError}
											cleanAsyncError={handleCleanAsyncError}
											onMissingData={handleMissingData}
											userInfo={userInfo}
											config={{
												omitHealthcare: false,
												disableResponsiblePharmacy: false
											}} />
									</Suspense>
								</KeraltyStepper>
							</Container>
						</Container>
					</Row>
					<Row style={{  justifyContent: 'center' }}>
						{isMobile ?
							<div >
								{serverError && <div style={{paddingBottom:30}}><KeraltyError error={t('errors.insuranceDisabledError')} color='#B50303' fontWeight={600} /></div> }
								<div style={{marginTop:0, marginBottom:serverError ? 10:30}}></div>
								<KeraltyDotChatTrigger
									color={'primary'}
									colordot={'primary'}
									colorlabel={'primary'}
									label={t('button.supportChat')}
									position={isMobile}
									right={false}
									svg={ SupportChatIcon2}
									onClick={handlerOnClick} />
							</div>
							:
							<div>
								<KeraltyDotChatTrigger
									color={'primary'}
									colordot={'primary'}
									colorlabel={'primary'}
									label={t('button.supportChat')}
									position={true}
									right={false}
									svg={SupportChatIcon}
									onClick={handlerOnClick} />
								{serverError && <div style={{paddingBottom:30}}><KeraltyError error={t('errors.insuranceDisabledError')} color='#B50303' fontWeight={600} /></div> }
							</div>
						}
					</Row>
				</Page>
			</Fragment>
		</>
	);
};

export default PatientRegistrationPage;
