import PublicHttpClient from '../publicHttpClient';
import authMapper from '../mappers/authMapper';
import { UserCredentialsSecurityDTO } from '../models/userCredentialsSecurityDTO';
// DOMAIN
import { AuthService } from '../../../domain/useCases/contracts/authService';
import { UserCredentials } from '../../../domain/entities/userCredentials';
import {AvailityInfo} from '../../../domain/entities/availityInfo';
import PrivateHttpClient from '../privateHttpClient';

const endpoint = 'auth';

const AuthRepository: AuthService = {
	login: (data: Omit<UserCredentials, 'phone'>) => {
		const body = authMapper.mapToUserCredentials(data);
		return PublicHttpClient.post(`${endpoint}/login`, body);
	},
	loginSecurity: (data: Omit<UserCredentials, 'phone'>) => {
		const body: UserCredentialsSecurityDTO = authMapper.mapToUserSecuirtyCredentials(data);
		//console.log(body);
		return PublicHttpClient.post(`${endpoint}/loginsecurity`, body);
	},
	updatePersonalInfoData: (body: any ) => {		
		return PrivateHttpClient.post(`${endpoint}/updateUsers/patient-info`, body.getObj );
	},
	loadUserBySession: (token: string, state: string) => {
		return PublicHttpClient.get(`${endpoint}/validatesession/${token}/${state}`)
			.then((res) => authMapper.mapFromLoadUserBySession(res));
	},
	registrationCheck: (data) => {
		const body = authMapper.mapToInitialRegistrationCheck(data);
		return PublicHttpClient.post(`${endpoint}/createuser/doCreateUser`, body);
	}, 
	availityStatus: (body: any) => 	
		 PublicHttpClient.post(`${endpoint}/insurance/status`, body)
	,
	updateInsuranceData: (body: any) => {		
		return PrivateHttpClient.post(`${endpoint}/updateUsers/insured`, body);
	},
	senselyAuthentication: (data) => {
		return PublicHttpClient.post(`${endpoint}/sensely/authenticate`, data);
	},
	validateAccount: (data) => {
		const body = authMapper.mapToValidateAccount(data);
		return PublicHttpClient.post(`${endpoint}/createuser/validate-account`, body);
	},
	validateAccountContactMethods: (data) => {
		const body = authMapper.mapToValidateAccountContactMethods(data);
		return PublicHttpClient.post(`${endpoint}/createuser/docreateuserecw`, body);
	},
	docreateUserEcw: (data) => {
		const body = authMapper.maoTodocreateUserEcwMethods(data);
		return PublicHttpClient.post(`${endpoint}/createuser/docreateuserecw`, body);
	},
	matchAccountInfo: (data) => {
		const body = authMapper.mapToMatchAccountInfo(data);
		return PublicHttpClient.post(`${endpoint}/createuser/docreateuserecwmc`, body);
	},
	loadLoginAllStates: (data: Omit<UserCredentials, 'phone'>) => {
		const body: UserCredentialsSecurityDTO = authMapper.mapToUserSecuirtyCredentials(data);
		return PublicHttpClient.post(`${endpoint}/doLoginAllStates`, body);
	},
	hintValidation: (patientId: string, state: string) => {
		return PrivateHttpClient.get(`${endpoint}/hintValidation?patientId=${patientId}&state=${state}`)	
			
	},
	validateTempSession: (email: string, state: string) => {
		return PublicHttpClient.get(`${endpoint}/forgotpass/validateTempSession/${email}/${state}`)
	},
	logoutRequest: (token: string, state: string) => {
		const body={token,state,isJob:false}
		return PrivateHttpClient.post(`${endpoint}/logout`, body);
	},
};

export default AuthRepository;
