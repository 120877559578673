const nsLogin = {
    en: {
        Sanitas: 'Sanitas',
        loginTitle: "Welcome",
        loginSubTitle: "Log in to continue",
        emailPlaceholder: 'Email',
        passPlaceholder: 'Password',
        loginButtonText: "Log in",
        logoutButtonText: "Log out",
        forgotPass: "Forgot password?",
        unblockAccount2: "Unblock account",
        createAccount: "Create account",
        disclaimerBioContent: "Be aware of Biometric Data section of the Terms and Conditions for MySanitas App.",
        disclaimerBioTitleAndroid: "Do you want to use your fingerprint to access MySanitas?",
        disclaimerBioTitleIOS: "Do you want to use Face ID to access MySanitas?",
        checkBiometricTextAndroid: "Use Fingerprint",
        checkBiometricTextIOS: "Use FACE ID",
        errorBiometricMessage: "Authentication failed, check the system settings and verify if your biometrical configuration is correct",
        disclaimerBioAgree: "Agree",
        diclaimerBioDisagree: "Disagree",
        updateButtonText: "GO TO STORE",
        updateNoticeText: "We've released a new version of MySanitas App, download the update in the Play Store to access our service",
        updateNoticeTextIos: "We've released a new version of MySanitas App, download the update in the App Store to access our service",
        version: "Version",
        firstBiologin: "Congratulations! Your account data has been saved. You will be able to use your Fingerprint to login from now on",
        firstBiologinTittle: "Fingerprint activation successful!",
        firstBiologinIos: "Congratulations! Your account data has been saved. You will be able to use your Face ID to login from now on",
        firstBiologinTittleIos: "Face ID activation successful!",
        ok: "OK",
        popupPdhError:"To check the result of this exam, you may request it from Patient support chat by picking on the 3 dots option",
        modalState: "We find that your email is registered to more than one State. Please select the State from which you want to receive our services:",
        modalPasswordExpired: "Because your password has expired. Please, click on the option below to update the password.",
        modalExceededAttempts: "Because you exceeded the number of login attempts to MySanitas. Please, click the option below to unblock your account",
        modalErrorSSOTitle: "There has been an Error.",
        modalErrorSSO: "There seems to be an issue with your credentials. Please contact support for assistance.",
        modalErrorSSO_msj_support: "Contact support",
        modalErrorSSO_msj_retry: "Try again",
        modalBlackList: "If you have any questions, please contact ",
        patientSupport: "patient support",
        download:"Download the mySanitas app ",
        accountBlocked: "Your account has been blocked",
        unblockAccount: "UNBLOCK ACCOUNT",
        Attempts4: "Your username or password is incorrect, you have four attempts left.",
        Attempts3: "Your username or password is incorrect, you have three attempts left.",
        Attempts2: "Your username or password is incorrect, you have two attempts left.",
        Attempts1: "Your username or password is incorrect, you have one attempts left.",
        InfoDoesntMatch: "Your information does not match.",
        InfoDoesntMatchLastTry: "Your information does not match. Be careful, if the next attempt fails, your account will be locked.",
        maintenanceText: "mySanitas app is out of service from [schedule] for scheduled maintenance. For sooner assistance, please contact our clinical call center at 1-844-665-4827, if this is medical emergency, please call 911. ",
        maintenanceApologize: 'We apologize for the inconvenience.',
        privateMaintenanceText: "mySanitas will undergo maintenance, and all services will be deactivated at [schedule]",
        authenticationTextOptions: 'Select the mobile number or email where you want to receive the authentication code. By clicking in one of the following options you are accepting to be contacted by email or by phone via a text message.',
        authenticationTextValidation: 'Type the authentication code sent to your desired method.',
        authenticationTextValidationTitle: 'Authentication code'
    },
    es: {
        Sanitas: 'Sanitas',
        loginTitle: "Bienvenidos",
        loginSubTitle: "Inicia sesión para continuar",
        emailPlaceholder: 'Correo electrónico',
        passPlaceholder: 'Contraseña',
        loginButtonText: "Iniciar sesión",
        logoutButtonText: "Cerrar sesión",
        forgotPass: "Olvidé mi contraseña",
        unblockAccount2: "Desbloquear cuenta",
        createAccount: "Registrarme",
        disclaimerBioContent: "Ten en cuenta la sección Datos biométricos  en  los Términos y condiciones de la app MySanitas.",
        disclaimerBioTitleAndroid: "¿Quieres utilizar tu huella digital para acceder a My Sanitas?",
        disclaimerBioTitleIOS: "¿Quieres usar Face ID para acceder a My Sanitas?",
        checkBiometricTextAndroid: "Usar Huella",
        checkBiometricTextIOS: "Usar FACE ID",
        errorBiometricMessage: "La autenticación falló, verifique la configuración del sistema y verifique si su configuración biométrica es correcta",
        disclaimerBioAgree: "\u00a0 \u00a0 ESTOY DE \u00a0 \u00a0 ACUERDO",
        diclaimerBioDisagree: "NO ESTOY DE ACUERDO",
        updateButtonText: "IR A LA TIENDA",
        updateNoticeText: "Hay una nueva versión de MySanitas disponible, descarga la actualización en Play Store para acceder a nuestros servicios",
        updateNoticeTextIos: "Hay una nueva versión de MySanitas disponible, descarga la actualización en App Store para acceder a nuestros servicios",
        version: "Versión",
        firstBiologin: "¡Felicidades! los datos de tu cuenta han sido almacenados. A partir de ahora podrás utilizar tu huella digital para iniciar sesión",
        firstBiologinTittle: "¡Activación con huella digital exitosa!",
        firstBiologinIos: "¡Felicidades!, los datos de tu cuenta han sido almacenados. A partir de ahora podrás utilizar tu Face ID para iniciar sesión",
        firstBiologinTittleIos: "¡Activación con Face ID exitosa!",
        ok: "OK",
        popupPdhError:"Para verificar el resultado de este examen, puede solicitarlo al chat de Atención al paciente seleccionando la opción de 3 puntos",
        modalState: "Encontramos que su correo electrónico está registrado en más de un Estado. Seleccione el Estado desde el que desea recibir nuestros servicios:",
        modalPasswordExpired: "Porque tu contraseña ha caducado. Por favor, has click en la opción de abajo para actualizar la contraseña.",
        modalExceededAttempts: "Porque excediste el número de intentos de ingreso a MySanitas. Por favor, has click en la opción de abajo para desbloquear tu cuenta",
        modalErrorSSOTitle: "Ha ocurrido un error.",
        modalErrorSSO: "Parece que hay un problema con tus credenciales. Por favor, contacta chat de soporte para obtener ayuda.",
        modalErrorSSO_msj_support: "Contactar a Soporte",
        modalErrorSSO_msj_retry: "Intentar nuevamente",
        modalBlackList: "Si tienes inquietudes, por favor contáctate con ",
        patientSupport: "atención al paciente",
        download:"Descarga mySanitas app ",
        accountBlocked: "Tu cuenta ha sido bloqueada",
        unblockAccount: "DESBLOQUEAR CUENTA",
        Attempts4: "Tu usuario o contraseña son incorrectos, te quedan cuatro intentos.",
        Attempts3: "Tu usuario o contraseña son incorrectos, te quedan tres intentos.",
        Attempts2: "Tu usuario o contraseña son incorrectos, te quedan dos intentos.",
        Attempts1: "Tu usuario o contraseña son incorrectos, te quedan un intentos.",
        InfoDoesntMatch: "Información incorrecta. Por favor inténtelo de nuevo.",
        InfoDoesntMatchLastTry: "Información incorrecta. Cuidado, si fallas en el próximo intento tu cuenta será bloqueada.",
        maintenanceText: "mySanitas app se encuentra fuera de servicio desde [schedule] por mantenimiento programado. Para obtener asistencia más rápida, por favor comuníquese con nuestro centro de llamadas clínicas al 1-844-665-4827. Si se trata de una emergencia médica, por favor llame al 911.",
        maintenanceApologize: 'Disculpa las molestias',
        privateMaintenanceText: "mySanitas entrará en mantenimiento, todos los servicios se inactivarán desde las [schedule]",
        authenticationTextOptions: 'Selecciona el número de celular o correo electrónico donde quieres recibir el método de verificación. Al elegir una de las opciones estás accediendo a ser contactado por teléfono o correo electrónico via mensaje de texto.',
        authenticationTextValidation: 'Digita el código de verificación enviado a tu método elegido. ',
        authenticationTextValidationTitle: 'Código de autenticación'
    }
};

export default nsLogin;
