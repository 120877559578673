
import moment, { utc } from 'moment';
import { FinalSaveRequest } from '../models/finalSaveRequest';
// DOMAIN
import { UserInfo } from '../../../domain/entities/userInfo';
import { PatientInfo } from '../../../domain/entities/patientInfo';

import FORMATS from 'app/ui-core/utils/formats';
import { formatPhone } from './formatsMapper';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const registerMapper = {
	mapToInitialSave: (domainObject: UserInfo & { userId: string; }): any => {
		const { password, userId, email, isEnglish, ...attrs } = domainObject;
		if(!!userId) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ISFBMAX, userId);
		else KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ISFBMAX, "");

		return {
			id: userId,
			email,
			pass: password,
			user: {
				id: userId,
				isFBMax: !!userId,
				sendByEmail: attrs.contactMethod === 'email',
				isEnglish,
				...(!userId ? {
					patientInformation: {
						email: email.trim(),
						firstName: attrs.firstName.trim(),
						lastName: attrs.lastName.trim(),
						dateOfBirth: moment(attrs.birthdate).utc().format(FORMATS.date2),
						homePhone: formatPhone(attrs.homePhone),
						mobile: formatPhone(attrs.cellphone), // PUBLIC - Personal Info
						sex: attrs.gender,
						address1: attrs.address1.trim(),
						address2: attrs.address2?.trim(),
						city: attrs.city.trim(),
						ssn: attrs.ssn?.trim(),
						state: attrs.state,
						zipCode: attrs.zipCode,
					}
				} : {
					patientInformation: { mobile: formatPhone(attrs.phone ?? attrs.cellphone) } // FB - Confirm Login
				})
			}
		}
	},
	mapToResendEmail: (domainObject: UserInfo & { userId: string; }): any => {
		const { password, userId, email, ...attrs } = domainObject;

		return {

			email,
			mobile: formatPhone(attrs.cellphone),
			isFBMax: !!userId,

		}
	},

	mapToInitialSaveByAccountNumber: (domainObject: UserInfo & { userId: string; }): any => {
		const { password, userId, isEnglish, email = '', ...attrs } = domainObject;

		if(userId) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ID_REGISTER, userId);
		const accountNumber = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_ID);
		
		if(!!userId) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ISFBMAX, userId);
		else KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ISFBMAX, "");

		return {
			id: userId,
			email,
			pass: password,
			user: {
				id: userId ?? accountNumber,
				idEcw: attrs.accountNumber,
				isEnglish,
				isFBMax: !!userId,
				sendByEmail: attrs.contactMethod === 'email',
				patientInformation: {
					dateOfBirth: moment(attrs.birthdate).format(FORMATS.dateISO8601),
					email,
					mobile: attrs.phone ? formatPhone(attrs.phone) : ''
				}
			}
		}
	},
	mapFromLoadUserInfo: (dto: any) => {
		const { patientInformation, id } = dto;
		const { dateOfBirth, sex, mobile, homePhone, relationship, ...userInfo } = patientInformation;

		return {
			...userInfo,
			tempId: id,
			birthdate: utc(dateOfBirth).format(FORMATS.date),
			gender: sex,
			cellphone: formatPhone(mobile),
			homePhone: formatPhone(homePhone),
			patientRelationship: relationship
		};
	},
	mapFromExpirationReg: (dto: any) => {

	},
	mapFromLoadMaxUserInfo: (dto: any): any => {
		const {
			id, memberNumber, memberFirstName, memberLastName, memberGender, memberRelationship,
			memberState, memberZipCode, memberHomePhoneNumber = '', memberEmailAddress,
			memberDateofBirth, memberCity, memberAddressLineOne, memberAddressLineTwo, contractNumber
		} = dto;

		return {
			tempId: id,
			firstName: memberFirstName,
			lastName: memberLastName,
			birthdate: utc(memberDateofBirth).format(FORMATS.date),
			city: memberCity,
			state: memberState,
			zipCode: memberZipCode,
			address1: memberAddressLineOne,
			address2: memberAddressLineTwo,
			homePhone: formatPhone(memberHomePhoneNumber),
			cellphone: formatPhone(memberHomePhoneNumber),
			email: memberEmailAddress,
			gender: memberGender,
			externalPartyId: memberNumber,
			patientRelationship: memberRelationship,
			contractNumber
		};
	},
	mapToFinalSave: (domainObject: PatientInfo & {
		userInfo: UserInfo,
		registerId: string,
		lang: string,
		isFBMax: boolean,
		isSelf: boolean,
		accountNumber?: string
	}): FinalSaveRequest => {
		const {
			isFBMax, isSelf, registerId,
			userInfo, lang,
			responsibleParty, insurance,
			consents,
			accountNumber
		} = domainObject;

		const {
			birthdate: gBirthdate, gender, cellphone: cellphoneUser, homePhone: homePhoneUser,
			...ui
		} = userInfo;

		const accountNumberUser = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_USER)
		//console.log('accountNumberUser --> ' , KeraltySWS?.swInstance?.getItem('accountNumberUser'));

		// const extPartyId = ui.externalPartyId? ui.externalPartyId || '' : accountNumberUser || '';
		const extPartyId = accountNumberUser ? ui.externalPartyId : '';

		const {
			selfGuarantor, firstName, lastName, cellphone,
			ssn, guarantorSsn, patientRelationship,
			guarantorReason, guarantorBirthdate,
			state, address, city, zipCode, IDFile, idFile64, idFileName,
			...rp
		} = responsibleParty;

		const { signature, acceptedEmail, acceptedPhone } = consents;
		let pharmacy = 'NA';
		let zipPharmacy = 'NA';
		// if (isFBMax) {
		if (rp.noFavoritePharmacy==false && rp.pharmacyName && rp.zipPharmacyCode)
		 {pharmacy = rp.pharmacyName; zipPharmacy= rp.zipPharmacyCode}
		 else{pharmacy='none' ; zipPharmacy=''};
		delete rp['pharmacyName'];
		delete rp['isAuthSelected'];
		// } else {
		// 	if (insurance.pharmacyName) pharmacy = insurance.pharmacyName;
		// 	delete insurance['pharmacyName'];
		// 	delete insurance['isAuthSelected'];
		// }

		if(!!isFBMax) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ISFBMAX, registerId);
		else KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.USER_ISFBMAX, "");

		KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.STATE, ui.state!);

		const payload = {
			id: registerId,
			isFBMax,
			idEcw: accountNumber,
			//externalPartyId: ui.externalPartyId || '',
			externalPartyId: extPartyId,
			signature,
			isEnglish: lang === 'en',
			patientInformation: {
				...ui,
				dateOfBirth: moment(gBirthdate).utc().format(FORMATS.dateISO8601),
				sex: responsibleParty.gender || gender || '',
				// CONSENTS FORMS OVERRIDE - FROM RESPONSIBLE PARTY INFORMATION TO PATIENT INFORMATION
				mobile: formatPhone(cellphoneUser),
				homePhone: formatPhone(homePhoneUser),
				address1: address,
				state: ui.state!,
				city,
				zipCode
			},
			responsiblePartyInformation: {
				...rp,
				self: isSelf,
				guarantor: !isSelf,
				ssn,
				guarantorSSN: guarantorSsn,
				responsiblePartyFirstName: firstName,
				responsiblePartyLastName: lastName,
				relationshipToThePatient: patientRelationship,
				reasonToHaveAGuarantor: guarantorReason,
				guarantorDateOfBirth: moment(guarantorBirthdate).utc().format(FORMATS.dateISO8601),
				mobile: formatPhone(cellphone),
				address,
				state,
				city,
				zipCode,
				idFile64,
				idFileName,
			},
			primaryInsuranceInformation: {
				insuranceCompany: insurance.company,
				companyId: insurance.companyId,
				planType: insurance.planType,
				phoneNumber: formatPhone(insurance.phone),
				nameOfInsured: insurance.insuredName,
				lastNameOfInsured: insurance.insuredLastName,
				patientRelationshipToInsured: insurance.patientToInsured,
				subscriberId: insurance.subscriberId,
				groupId: insurance.groupId,
				holderInsured: {
					name: insurance.name || '',
					lastName: insurance.lastName || '',
					dateOfBirth: insurance.dateOfBirth ? moment(insurance.dateOfBirth).utc().format(FORMATS.dateISO8601) : '',
				}
			},
			secondaryInsuranceInformation: {
				insuranceCompany: insurance.company2nd,
				companyId: insurance.companyId2nd,
				planType: insurance.planType2nd,
				phoneNumber: formatPhone(insurance.phone2nd),
				nameOfInsured: insurance.insuredName2nd,
				lastNameOfInsured: insurance.insuredLastName2nd,
				patientRelationshipToInsured: insurance.patientToInsured2nd,
				subscriberId: insurance.subscriberId2nd,
				groupId: insurance.groupId2nd,
				holderInsured: {
					name: insurance.name2nd || '',
					lastName: insurance.lastName2nd || '',
					dateOfBirth: insurance.dateOfBirth2nd ? moment(insurance.dateOfBirth2nd).utc().format(FORMATS.dateISO8601) : '',
				}
			},
			// REVIEW WITH BE
			pharmacyInformation: { pharmacy,
  			 noFavoritePharmacy:rp.noFavoritePharmacy,
   				zipcode:zipPharmacy

			},
			// END REVIEW WITH BE
			healthOptins: {
				email: Boolean(acceptedEmail),
				phone: Boolean(acceptedPhone)
			}
		};

		return payload;
	}
};

export default registerMapper;
