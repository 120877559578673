import { useAppDispatch, useAppSelector } from "adapter/hooks";
import { useCallback } from "react";
import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';

const useForgotPassword = () => {

	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const dispatch = useAppDispatch();

	const openPublicChat = useCallback(async () => {
		if (sanitasState.isShow && sanitasState.isSetted) {
		  //await dispatch(sanitasAdapterMethods.shutdown());
		  dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: '', state: false }));
		}
		dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: 'support', state: true }));
		}, [dispatch])

	return {
		openPublicChat
	}
}

export default useForgotPassword;
