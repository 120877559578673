import moment from 'moment';
import { createSelector } from 'reselect';
import { RootState } from '../store';
import { RegisterState } from './registerState';
import { baseSelectors } from '../base/baseSelectors';
import { DefaultPatientResponsibleInfo } from '../../domain/valueObjects/defaultPatientResponsibleInfo';
import { DefaultPatientConsents } from '../../domain/valueObjects/defaultConsents';
import { DefaultPatientInsuranceInfo } from '../../domain/valueObjects/defaultPatientInsuranceInfo';
import { IPartialRegister } from 'domain/entities/userInfo';
import { RegisterInitialState } from './registerSlice';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const selectSelf = (state: RootState): RegisterState => state.register;

const selectors = baseSelectors(selectSelf);

const selectUserId = createSelector(selectSelf, ({ userId }) => userId);
const selectRegisterId = createSelector(selectSelf, ({ registerId }) => registerId);
const selectAccountNumber = createSelector(selectSelf, ({ accountNumber }) => accountNumber);
const selectPartialRegister = createSelector(selectSelf, ({ partialRegister }) => {

	const session: IPartialRegister = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ITEMS.TEMP_SESSION) || JSON.stringify(RegisterInitialState.partialRegister))

	return 	{
		...partialRegister,
		tempSessionId: partialRegister.tempSessionId || session.tempSessionId,
		state: partialRegister.state || session.state
	}
});

const selectUserInfo = createSelector(selectSelf, ({ userInfo }) => userInfo ? ({
	...userInfo,
	displayName: `${userInfo.firstName} ${userInfo.lastName}`,
	...(userInfo.birthdate ? { birthdate: moment(userInfo.birthdate).toDate() } : {})
}) : {} as any);

const selectIsFBMax = createSelector(selectSelf, ({ userInfo }) => userInfo?.externalPartyId);

const selectPatientInfo = createSelector(
	selectSelf,
	({ patientInfo, userInfo }: RegisterState) => {
		let { responsibleParty: rp, insurance: ins, consents: cs } = patientInfo;

		if (!rp) rp = DefaultPatientResponsibleInfo;
		if (!ins) ins = DefaultPatientInsuranceInfo;
		if (!cs) cs = DefaultPatientConsents;

		return {
			responsibleParty: {
				...rp,
				...(rp?.guarantorBirthdate ? { guarantorBirthdate: moment(rp?.guarantorBirthdate).toDate() } : {})
			},
			insurance: ins,
			consents: {
				...cs,
				...(cs?.date ? { date: moment(cs?.date).toDate() } : {}),
				signature: `${userInfo?.firstName} ${userInfo?.lastName}`,
			}
		}
	}
);

const registerSelectors = {
	...selectors,
	selectUserId,
	selectIsFBMax,
	selectUserInfo,
	selectPatientInfo,
	selectPartialRegister,
	selectRegisterId,
	selectAccountNumber
};

export default registerSelectors;
