// 3RD PARTY
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// BASE
import { baseActions } from '../base/baseActions';
import baseReducer from '../base/baseReducer';
import { RootState } from '../store';
import { RegisterState } from './registerState';
// DOMAIN
import registerUseCase from '../../domain/useCases/register';
// INFRASTRUCTURE
import RegisterRepository from '../../infrastructure/keraltyApi/repositories/register';
import AuthRepository from '../../infrastructure/keraltyApi/repositories/auth';
import UsersRepository from '../../infrastructure/keraltyApi/repositories/users';
import { DefaultPatientResponsibleInfo } from '../../domain/valueObjects/defaultPatientResponsibleInfo';
import moment from 'moment';
import { EcwRepository } from '../../infrastructure/keraltyApi/repositories/ecw';

import FORMATS from 'app/ui-core/utils/formats';
import i18n from 'app/ui-core/i18n';
import PublicHttpClient from 'infrastructure/keraltyApi/publicHttpClient';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import sessionStorage from 'redux-persist/lib/storage/session'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import appConfig from 'config';
import { DaraUserECW } from 'domain/entities/accountInfo';

const initialState: RegisterState = {
	userId: '', // ONLY MAX USERS
	userInfo: undefined, // REGISTER FLOW DATA - PT 1
	registerId: '',
	patientInfo: {
		responsibleParty: undefined,
		insurance: undefined,
		consents: undefined
	}, // REGISTER FLOW DATA - PT 2
	partialRegister: {
		tempSessionId: '',
		state: ''
	},
	insuranceList: [],
	// BASE
	isLoading: false,
	error: undefined,
	accountNumber: undefined
};

export const RegisterInitialState = initialState;

export const persistConfig = {
	key: 'register',
	storage: sessionStorage,
	whitelist: ['userId', 'registerId', 'userInfo', 'hadSanitas', 'hasAccountNumber', 'accountNumber'],
	transforms: [
		encryptTransform	({
			secretKey: appConfig.KERALTY_SWS_KEY,
		  	onError: function (error) {
				// Handle the error.
		  	},
		}),
	],
};

//#region ASYNC ACTIONS
const asyncActions = {
	validateAccount: createAsyncThunk('register/validateAccount',
		async (value: any) => {
			return await registerUseCase.validateAccount(AuthRepository, value);
		}),
	validateAccountContact: createAsyncThunk('register/validateAccountContact',
		async (value: any, { getState }) => {
			const { register: { userInfo: { birthdate, accountNumber } } } = getState() as RootState;
			const res = await registerUseCase.validateAccountContactMethods(
				AuthRepository, { birthdate, accountNumber, ...value });

			if (res.matchEmail || res.matchPhone) return res;
			else return Promise.reject({
				name: 'validateAccountContact', message: '190', code: '200'
			});
		}),
	matchAccountInfo: createAsyncThunk('register/matchAccountInfo',
		async (value: any) => {
			return await registerUseCase.matchAccountInfo(AuthRepository, value);
		}),
	docreateUserEcw: createAsyncThunk('register/docreateUserEcw',
		async (value: DaraUserECW) => {
			return await registerUseCase.docreateUserEcw(AuthRepository, value);
		}),
	registrationCheck: createAsyncThunk('register/registrationCheck',
		async (stepOneFormValues: any) => {
			return await registerUseCase.registrationCheck(AuthRepository, stepOneFormValues);
		}),
	loadElegibilityData: createAsyncThunk('register/loadElegibilityData',
		async (id: string) => {
			return registerUseCase.getloadElegibilityData(RegisterRepository, id);
		}),
	isEmailAvailable: createAsyncThunk('register/isEmailAvailable',
		async (email: string) => {
			return await registerUseCase.isEmailAvailable(UsersRepository, email, 'FL');
		}),
	getInsuranceList: createAsyncThunk('register/insuranceList',
		async () => {
			return registerUseCase.getInsuranceList(EcwRepository);
		}),
	initialSave: createAsyncThunk('register/initialSave',
		async (payload, { getState }) => {
			const { register: { userId, userInfo } } = getState() as RootState;
			const result = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.REGISTRATION_CHECK)
			// const result = localStorage.getItem('registrationCheck');
			const res: any = JSON.parse(result ? result : '{}')
			return await registerUseCase.initialSave(RegisterRepository, { userId: res?.id ?? userId, ...userInfo });
		}),
	resendEmail: createAsyncThunk('register/resendemail',
		async (payload, { getState }) => {
			const { register: { userId, userInfo } } = getState() as RootState;
			return await registerUseCase.resendEmail(RegisterRepository, { userId, ...userInfo });
		}),
	// PENDING
	initialSaveByAccount: createAsyncThunk('register/initialSaveByAccount',
		async (payload, { getState }) => {
			const { register: { userId, userInfo } } = getState() as RootState;
			return await registerUseCase.initialSaveByAccountNumber(RegisterRepository, { userId, ...userInfo });
		}),
	loadUserInfo: createAsyncThunk('register/loadUserInfo',
		async (data: {code: string, state: string ,email: string}) => {
			return await registerUseCase.loadUserInfo(RegisterRepository, data);
		}),
	loadMaxUserInfo: createAsyncThunk('register/loadMaxUserInfo',
		async (data: { id: string, email: string }) => {
			return await registerUseCase.loadMaxUserInfo(RegisterRepository, data);
		}),
	loadUserInfoByCode: createAsyncThunk('register/loadUserInfoByCode',
		async (data: any) => {
			return await registerUseCase.loadUserInfoByCode(RegisterRepository, data);
		}),
	loadMaxUserInfoByCode: createAsyncThunk('register/loadMaxUserInfoByCode',
		async (data: {id: string,email: string}) => {
			return await registerUseCase.loadMaxUserInfoByCode(RegisterRepository, data);
		}),
	createAccount: createAsyncThunk('register/createAccount',
		async (data, { getState }) => {
			const {
				register: { patientInfo, userInfo, registerId, accountNumber },
				// user: { lng }
			} = getState() as RootState;
			return await registerUseCase.createAccount(RegisterRepository, {
				...patientInfo, userInfo, registerId, lang: i18n.language.includes('en') ? 'en' : 'es', accountNumber
			});
		}),
	getExpirationConsents: createAsyncThunk('register/expirationConsents',
		async (data: { state: string, id: string, isFBMax: string }) => {
			return await registerUseCase.expirationConsents(RegisterRepository, data);
		}),
};
//#endregion

const RegisterSlice = createSlice({
	name: 'register',
	initialState,
	reducers: {
		...baseActions<RegisterState>(),
		setAccountNumber: (state, { payload }) => {
			return {
				...state,
				accountNumber: payload
			};
		},
		updateUserInfo: (state, { payload }) => {
			state.userInfo = {
				...state.userInfo,
				...payload
			};
		},
		updatePartialRegister:(state, {payload}) => {
			state.partialRegister = {
				...state.partialRegister,
				...payload
			}
		},
		setPatientInfoProp: (state, { payload }) => {
			state.patientInfo = {
				...state.patientInfo,
				...payload
			};
		},
		setSelf: (state) => {
			const {
				address1, address2, birthdate, state: elgState, contactMethod,
				...user
			} = state.userInfo || {};

			state.patientInfo = {
				...state.patientInfo,
				responsibleParty: {
					...DefaultPatientResponsibleInfo,
					...user,
					address: address1 || address2 || '',
					// guarantorSsn: ssn,
					...(elgState ? { state: elgState } : {}),
					...(birthdate ? { guarantorBirthdate: moment(birthdate).format(FORMATS.date) } : {}),
					selfGuarantor: 1
				}
			}
		},
		setGuarantor: (state) => {
			state.patientInfo = {
				...state.patientInfo,
				responsibleParty: {
					...DefaultPatientResponsibleInfo,
					selfGuarantor: 2
				}
			}
		},
		resetPatientInfo: (state) => {
			state.patientInfo = {
				responsibleParty: undefined,
				insurance: undefined,
				consents: undefined
			}
		}
	},
	extraReducers: (builder) => {
		builder
			//#region validateAccount
			.addCase(asyncActions.validateAccount.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.validateAccount.fulfilled, (state) => { baseReducer.fullfilled(state); })
			.addCase(asyncActions.validateAccount.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion

			//#region validateAccountContact
			.addCase(asyncActions.validateAccountContact.pending, (state) => {
				if (state.userInfo) {
					state.userInfo.emailDisabled = undefined;
					state.userInfo.phoneDisabled = undefined;
				}
				baseReducer.pending(state);
			})
			.addCase(asyncActions.validateAccountContact.fulfilled, (state, { payload }) => {
				if (state.userInfo) {
					if (payload.userId !== 'NO_FB') { state.userId = payload.userId };
					if (payload.state) KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE, payload.state);
					state.userInfo.emailDisabled = !payload.matchEmail;
					state.userInfo.phoneDisabled = !payload.matchPhone;
				}
				baseReducer.fullfilled(state);
			})
			.addCase(asyncActions.validateAccountContact.rejected, (state, action) => {
				if (state.userInfo) {
					state.userInfo.emailDisabled = undefined;
					state.userInfo.phoneDisabled = undefined;
				}
				baseReducer.rejected(state, action);
			})
			//#endregion

			//#region matchAccountInfo
			.addCase(asyncActions.matchAccountInfo.pending, (state) => {
				state.userId = undefined;
				if (state.userInfo) {
					state.userInfo.accountNumber = undefined;
					state.userInfo.emailDisabled = undefined;
					state.userInfo.phoneDisabled = undefined;
				}
				baseReducer.pending(state);
			})
			.addCase(asyncActions.matchAccountInfo.fulfilled, (state, { payload }) => {
				if (payload.userId !== 'NO_FB') state.userId = payload.userId;
				if (state.userInfo) {
					state.userInfo.accountNumber = payload.accountNumber;
					state.userInfo.emailDisabled = !payload.matchEmail;
					state.userInfo.phoneDisabled = !payload.matchPhone;
				}
				baseReducer.fullfilled(state);
			})
			.addCase(asyncActions.matchAccountInfo.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion

			//#region registrationCheck
			.addCase(asyncActions.registrationCheck.pending, (state) => {
				state.userId = undefined;
				baseReducer.pending(state);
			})
			.addCase(asyncActions.registrationCheck.fulfilled, (state) => { baseReducer.fullfilled(state); })
			.addCase(asyncActions.registrationCheck.rejected, (state, action) => {
				state.userId = undefined;
				baseReducer.rejected(state, action);
			})
			//#endregion

			//#region isEmailAvailable
			.addCase(asyncActions.isEmailAvailable.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.isEmailAvailable.fulfilled, (state) => { baseReducer.fullfilled(state); })
			.addCase(asyncActions.isEmailAvailable.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion
			
			//#region docreateUserEcw
			.addCase(asyncActions.docreateUserEcw.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.docreateUserEcw.fulfilled, (state) => { baseReducer.fullfilled(state); })
			.addCase(asyncActions.docreateUserEcw.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion

			//#region initialSave
			.addCase(asyncActions.initialSave.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.initialSave.fulfilled, (state) => { baseReducer.fullfilled(state); })
			.addCase(asyncActions.initialSave.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion

			//#region initialSaveByAccount
			.addCase(asyncActions.initialSaveByAccount.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.initialSaveByAccount.fulfilled, (state) => { baseReducer.fullfilled(state); })
			.addCase(asyncActions.initialSaveByAccount.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion

			//#region getInsuranceList
			.addCase(asyncActions.getInsuranceList.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.getInsuranceList.fulfilled, (state, { payload }) => {
				state.insuranceList = payload;
				baseReducer.fullfilled(state);
			})
			.addCase(asyncActions.getInsuranceList.rejected, (state, action) => { baseReducer.rejected(state, action) })
			//#endregion

			//#region loadUserInfo
			.addCase(asyncActions.loadUserInfo.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.loadUserInfo.fulfilled, (state, { payload: { tempId, ...attrs } }) => {
				state.userInfo = { ...attrs, contactMethod: state.userInfo?.contactMethod };
				state.registerId = tempId;
				baseReducer.fullfilled(state);
			})
			.addCase(asyncActions.loadUserInfo.rejected, (state, action) => {
				state.userInfo = undefined;
				baseReducer.rejected(state, action);
			})
			//#endregion

			//#region loadMaxUserInfo
			.addCase(asyncActions.loadMaxUserInfo.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.loadMaxUserInfo.fulfilled, (state, { payload: { tempId, ...attrs } }) => {
				state.userInfo = { ...attrs, contactMethod: state.userInfo?.contactMethod };
				state.registerId = tempId;
				baseReducer.fullfilled(state);
			})
			.addCase(asyncActions.loadMaxUserInfo.rejected, (state, action) => {
				state.userInfo = undefined;
				baseReducer.rejected(state, action);
			})
			//#endregion

			//#region loadUserInfoByCode
			.addCase(asyncActions.loadUserInfoByCode.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.loadUserInfoByCode.fulfilled, (state, { payload: { tempId, ...attrs } }) => {
				state.userInfo = { ...attrs, contactMethod: state.userInfo?.contactMethod };
				state.registerId = tempId;
				baseReducer.fullfilled(state);
			})
			.addCase(asyncActions.loadUserInfoByCode.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion

			//#region loadMaxUserInfoByCode
			.addCase(asyncActions.loadMaxUserInfoByCode.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.loadMaxUserInfoByCode.fulfilled, (state, { payload: { tempId, ...attrs } }) => {
				state.userInfo = { ...attrs, contactMethod: state.userInfo?.contactMethod };
				state.registerId = tempId;
				baseReducer.fullfilled(state);
			})
			.addCase(asyncActions.loadMaxUserInfoByCode.rejected, (state, action) => { baseReducer.rejected(state, action); })
			//#endregion

			//#region createAccount
			.addCase(asyncActions.createAccount.pending, (state) => { baseReducer.pending(state); })
			.addCase(asyncActions.createAccount.fulfilled, () => initialState)
			.addCase(asyncActions.createAccount.rejected, (state, action) => { baseReducer.rejected(state, action) })
			//#endregion

			.addCase(REHYDRATE, () => { });
	}
});

export const registerActions = {
	...RegisterSlice.actions,
	...asyncActions
};

export default RegisterSlice.reducer;
